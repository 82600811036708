//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
    documentLableDelete,
    documentLableExport,
    documentLablePage,
} from '@/api/modular/fileStream/documentLabelManage'
import { STable, XDown } from '@/components'
import addForm from './addForm.vue'
import editForm from './editForm.vue'
import PersonList from './table.vue'
export default {
    components: {
        STable,
        addForm,
        editForm,
        XDown,
        PersonList,
    },
    data() {
        return {
            // 查詢參數
            queryParam: {},
            tags: [],
            tagActive: 0,
            tagActiveRow: {},
            tagTodoActive: -1,
            // 表頭
            columns: [
                {
                    title: '標簽名稱',
                    align: 'center',
                    dataIndex: 'name',
                },
                {
                    title: '標簽順序',
                    align: 'center',
                    dataIndex: 'sort',
                },
            ],
            tstyle: { 'padding-bottom': '0px', 'margin-bottom': '10px' },
            // 加載數據方法 必須為 Promise 對象
            loadData: (parameter) => {
                return documentLablePage(Object.assign(parameter, this.queryParam)).then((res) => {
                    return res.data
                })
            },
            selectedRowKeys: [],
            selectedRows: [],
            options: {
                alert: {
                    show: true,
                    clear: () => {
                        this.selectedRowKeys = []
                    },
                },
                rowSelection: {
                    selectedRowKeys: this.selectedRowKeys,
                    onChange: this.onSelectChange,
                },
            },
        }
    },
    created() {
        if (this.hasPerm('documentLable:edit') || this.hasPerm('documentLable:delete')) {
            this.columns.push({
                title: '操作',
                width: '150px',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' },
            })
        }
        this.getTags()
        document.onclick = () => {
            this.tagTodoActive = -1
        }
    },
    beforeDestroy() {
        document.onclick = null
    },
    methods: {
        showTodo(tagIndex) {
            this.tagTodoActive = tagIndex
        },
        /**
         * 單個刪除
         */
        singleDelete(record) {
            console.log(record)
            const param = [{ id: record.id }]
            this.documentLableDelete(param)
        },
        /**
         * 批量刪除
         */
        batchDelete() {
            const paramIds = this.selectedRowKeys.map((d) => {
                return { id: d }
            })
            this.documentLableDelete(paramIds)
        },
        documentLableDelete(record) {
            documentLableDelete(record).then((res) => {
                if (res.success) {
                    this.$message.success('刪除成功')
                    this.getTags()
                } else {
                    this.$message.error('刪除失敗') // + res.message
                }
            })
        },
        /**
         * 批量導出
         */
        batchExport() {
            const paramIds = this.selectedRowKeys.map((d) => {
                return { id: d }
            })
            documentLableExport(paramIds).then((res) => {
                this.$refs.batchExport.downloadfile(res)
            })
        },
        handleOk() {
            this.getTags()
        },
        changeActive(tag, tagIndex) {
            this.tagActive = tagIndex
            this.tagActiveRow = tag
        },
        onSelectChange(selectedRowKeys, selectedRows) {
            this.selectedRowKeys = selectedRowKeys
            this.selectedRows = selectedRows
        },
        getTags() {
            documentLablePage(this.queryParam).then((res) => {
                if (res.code === 200) {
                    this.tags = res.data.rows
                    this.tagActiveRow = res.data.rows.length > 0 ? res.data.rows[0] : {}
                } else {
                    this.$message.warning(res.message)
                }
            })
        },
    },
}
